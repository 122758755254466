<template>
  <div>
    <v-container class="bigScreenForm">
      <v-row>
        <v-col>
          <v-row class="my-12">
            <h3 v-text="$t('common.categoriesAndTeams')" />
          </v-row>
          <v-row>
            <div :style="listContainer">
              <category-and-teams-list
                :key="categories.length"
                text-field="name"
                :title="$t('project.academy.categories.titleCategories')"
                :description="$t('project.academy.categories.descriptionCategories')"
                :add-button-text="$t('project.academy.categories.addCategory')"
                :items="listCategories"
                @onChanged="itemsList => { listCategories = itemsList; update('Categories') }"
                @onSelect="itemToSelect => categoryId = itemToSelect"
              />
            </div>
            <div :style="listContainer">
              <category-and-teams-list
                :key="categoryId"
                text-field="suffix"
                :title="$t('project.academy.categories.titleTeams')"
                :description="$t('project.academy.categories.descriptionTeams')"
                :add-button-text="$t('project.academy.categories.addTeam')"
                :items="listTeams[categoryId]"
                :ids="{ categoryId, projectId }"
                :disabled="!listCategories.length || !categoryId"
                @onChanged="itemsList => { listTeams[categoryId] = itemsList; update('Teams') }"
              />
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'AcademyCategoriesAndTeams',
  components: {
    CategoryAndTeamsList: () => import('@/modules/academy/components/AcademyCategoriesList'),
  },
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, default: null },
  },
  data() {
    return {
      listCategories: [],
      teamsById: {},
      categoryId: null,
    }
  },
  computed: {
    ...mapGetters({ categories: 'academy/categories' }),
    ...mapGetters({ teams: 'academy/teams' }),
    listContainer() {
      return {
        width: '45%',
        height: '66vh',
        marginRight: '4px',
        background: this.$vuetify.theme.themes.light.inactive,
        border: '2px solid #dadada',
        borderRadius: '12px',
        boxShadow: `2px 2px 2px 1px ${this.$vuetify.theme.themes.light.inactive}`,
      }
    },
    listTeams: {
      get() {
        return this.categoryId ? this?.teamsById : []
      },
      set(teams) {
        const allCategories = this.listCategories.map(category => category.id)
        const teamsByCategory = allCategories.map(category => teams.filter(team => team.categoryId === category))
        this.teamsById = teamsByCategory.reduce((acc, curr, i) => ({ ...acc, [allCategories[i]]: curr }), {})
      },
    },
  },
  watch: {
    categories(value) {
      this.listCategories = value
    },
    teams(value) {
      this.listTeams = value
    },
  },
  created() {
    this.listCategories = this.categories
    this.listTeams = this.teams
  },
  methods: {
    async update(listType) {
      const { organizationId, projectId } = this
      if (listType === 'Categories' && this.listCategories.every(item => item?.name)) {
        this.runAsync(() => {
          this.$store.dispatch('academy/updateCategories',
            {
              organizationId,
              projectId,
              itemsList: this.listCategories,
            })
        })
      } else if (listType === 'Teams') {
        const combinedTeams = Object
          .values(this.listTeams)
          .map(category => category.filter((team, i, group) => group.map(e => e.suffix).indexOf(team.suffix) === i)) // only keep unique items, since the suffix and id are the same it would cause conflicts
          .flat()
        combinedTeams.every(item => item?.suffix) && this.runAsync(() => {
          this.$store.dispatch('academy/updateTeams',
            {
              organizationId,
              projectId,
              itemsList: combinedTeams,
            })
        })
      }
    },
  },
}
</script>
